<template>
    <div class="box">
        <div class="box-header">
            <h3 class="box-title">Seznam aut</h3>
        </div>
        <div class="box-body">
            <div class="row">
                <div class="col-lg-4">
                    <div class="input-group input-group-sm" style="width: 100%">
                        <input v-model="filter" type="text" name="table_search" class="form-control" placeholder="Hledat">
                    </div>
                </div>
                <div v-if="!user.cus" class="col-lg-8">
                    <button class="btn btn-default btn-flat btn-sm pull-right" @click="toggleModal()"><i class="fa fa-envelope-open-text"></i> Odeslat email</button>
                </div>
            </div>
            <br>
            <div v-if="allCars.length" class="table-responsive no-padding" style="width: auto; overflow: auto">
                <table class="table table-hover">
                    <thead>
                    <tr>
                        <th style="width: 64px"><input type="checkbox" v-model="selectAll"> Vše</th>
                        <th style="width: 36px"></th>
                        <th>Název</th> 
                        <th v-if="!user.cus">Zákazník</th>
                        <th v-if="!(user.cus && !user.admin)">Řidič</th>
                        <th>VIN</th>
                        <th>SPZ</th>
                        <th>Kód motoru</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="car in getCars" :key="car.carid">
                            <td><input type="checkbox" v-model="selectedCars" :value="car.carid" number></td>
                            <td><span class="label" :style="`border: solid 1px #EEEFF4;background-color: ${car.color}; color: ${car.color}`">_</span></td>
                            <td style="cursor: pointer" @click="makeCurrentCar(car.carid)"><a>{{car.name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentCus(car.customerid)" v-if="!user.cus"><a>{{car.company_name}}</a></td>
                            <td style="cursor: pointer" @click="makeCurrentMnt(car.maintainerid)" v-if="!(user.cus && !user.admin)"><a>{{car.fullname}}</a></td>
                            <td>{{car.vin_code}}</td>
                            <td>{{car.licence_plate}}</td>
                            <td>{{car.engine_code}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else class="callout callout-info">
                <p><i class="icon fa fa-info"></i>Nejsou evidována žádná auta.</p>                          
            </div>
        </div>
        <div v-if="!loaded" class="overlay">
            <i class="fa fa-refresh fa-spin"></i>
        </div>

        <!-- 
        TODO: Zeptat se na označení emailu názvem auta, jelikož při vícero autech nemusí být jasné pro jaké auto u daného zákazníka je email určený. 
        Možná řešení:
            - placeholdery do textu {auto}, {spz}, {ridic}
            - přidání názvu auta/spz do předmětu nebo jako footer emailu 
        -->

        <div v-if="emailModalVisible" class="overlay dark">

            <div class="row">
                <div class="col-sm-6 col-md-6" style="margin:2rem">
                    <div class="box box-solid box-primary modal-animation">
                        <div class="box-header">
                            <h3 class="box-title">Odeslání emailu</h3>
                            <div class="box-tools pull-right">
                                <button class="btn btn-box-tool" data-toggle="tooltip" title="Zavřít" @click="emailModalVisible = false;"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                        <div class="box-body white">
                                
                            <label for="emailSubject" class="control-label">Předmět</label>
                            <input id="emailSubject" class="form-control" style="margin-bottom: 1rem" v-model="email.subject" type="text">
                            <span v-if="!email.subject" class="help-block">Povinný údaj</span>

                            <label for="emailBody" class="control-label">Zpráva</label>
                            <textarea id="emailBody" v-model="email.body" style="resize: none; margin-bottom: 1rem; white-space: pre-wrap;" class="form-control" rows="5"></textarea>
                            <span v-if="!email.body" class="help-block">Povinný údaj</span>

                            <button class="btn btn-primary btn-flat btn-sm pull-right" @click="submit()"><i class="fa fa-envelope"></i> Odeslat</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-md-2"  style="margin:2rem 2rem 2rem -1rem">
                    <div class="box box-solid box-primary modal-animation">
                        <div class="box-header">
                            <h3 class="box-title">Proměnné</h3>
                        </div>
                        <div class="box-body gray">
                            <p><span class="placeholder">{auto}</span><span class="pull-right">Nahradí se názvem auta</span></p>
                            <p><span class="placeholder">{spz}</span><span class="pull-right">Nahradí se SPZ auta</span></p>
                            <p><span class="placeholder">{ridic}</span><span class="pull-right">Nahradí se řidičem auta</span></p>
                            <p class="placeholder-help">Vložte tyto proměnné do textu a ony se automaticky nahradí informacemi daného auta</p>
                        </div>
                    </div>
                </div>
                <div class="clearfix visible-xs-block"></div>
            </div>

        </div>

    </div>
</template>

<script>
const name = 'SummaryEmail';

import { mapGetters, mapActions } from "vuex";
import api from "@/store/classes/api";

export default {
    name,
    data() {
        return {
            filter: '',
            loaded: false,
            selectedCars: [],
            email: {
                subject: "",
                body: ""
            },
            emailModalVisible: false
        };
    },
    computed: {
        ...mapGetters(["getAllPages", 'allCars', 'user', 'allMaintainers', 'group']),
        selectAll: {
            get: function () {
                return this.allCars ? this.selectedCars.length == this.allCars.length : false;
            },
            set: function (value) {
                var selectedCars = [];

                if (value) {
                    this.allCars.filter(item => {
                        return (this.$helpers.filterCheck(this.filter, item.name) || this.$helpers.filterCheck(this.filter, item.licence_plate) || this.$helpers.filterCheck(this.filter, item.vin_code));
                    }).forEach(function (car) {
                        selectedCars.push(car.carid);
                    });
                }

                this.selectedCars = selectedCars;
            }
        },
        getCars() {
            var allCars = this.allCars

            if (!allCars.length)
            {
                return []
            }
            
            // Filtr provedeme na vícero položkách auta -> možnost vyhledávání jak podle jména, tak podle VIN, atd..
            return allCars.filter(item => {
                return (this.$helpers.filterCheck(this.filter, item.name) || this.$helpers.filterCheck(this.filter, item.licence_plate) || this.$helpers.filterCheck(this.filter, item.vin_code));
            })
        }
    }, 
    methods: {
        ...mapActions(["makeActiveTab", "addTab", "fetchCars", 'makeCurrentCar', 'makeCurrentCus', 'makeCurrentMnt', 'fetchMaintainers']),
        submit() {

            if(this.email.subject && this.email.body) {

                var failedEmails = 0;
                this.selectedCars.forEach(carid => {
                    if(!this.sendEmail(carid)) failedEmails++;
                })

                if(failedEmails == this.selectedCars.length) {
                    this.toggleModal();
                    this.$alerts.toast('Celkem '+failedEmails+' emailů se nepodařilo odeslat.', 'warning');
                } else if(failedEmails > 0) {
                    this.toggleModal();
                    this.$alerts.toast('Nepodařilo se odeslat žádný email.', 'error');
                } else {
                    this.toggleModal();
                    this.$alerts.toast('Všechny emaily byly úspešně odeslány!', 'success');

                    this.email.subject = "";
                    this.email.body = "";
                    this.selectedCars = [];
                }

            } else {
                this.$alerts.toast('Nejsou vyplněny všechny údaje!', 'error');
            }

        },
        getMaintainer(id) {
            var allMaintainers = this.allMaintainers;
            
            if(!allMaintainers) {
                return null;
            }

            return allMaintainers.filter( item => {
                return item.maintainerid == id;
            })[0];

        },
        getCar(id) {
            var allCars = this.allCars;

            if(!allCars) {
                return null;
            }

            return allCars.filter( item => {
                return item.carid == id;
            })[0];
        },
        toggleModal() {
            if(!this.emailModalVisible) {
                //Toggle on
                if(this.selectedCars.length == 0) {
                    this.$alerts.toast('Nejprve musíte vybrat alespoň 1 auto!', 'warning');
                    return;
                }

            }
            this.emailModalVisible = !this.emailModalVisible;
        },
        async sendEmail(carid) {

            const car = this.getCar(carid);
            const maintainer = this.getMaintainer(car.maintainerid);

            const placeholders = {
                "{auto}":car.name,
                "{spz}":car.licence_plate,
                "{ridic}":car.fullname
            }

            const mail = {
                sourceid: "car-"+carid,
                from: this.group.email,
                to: maintainer.email,
                subject: this.replacePlaceholders(this.email.subject, placeholders),
                html: this.replacePlaceholders(this.email.body, placeholders)
            }

            const res = await api.post("email/send",mail)

            if (res === "OK") {
                return true;
            }
            return false;
        },
        replacePlaceholders(str, placeholders) {
            return str.replace(/{\w+}/g, function(match) {
                return placeholders[match] || match;
            });
        }
    },
    created() {
        this.fetchCars().then(() => {
            this.fetchMaintainers().then(() => {
                this.loaded = true;
            });
        });
        

        const page = this.getAllPages.filter(page => page.name == name)[0]   
        this.addTab(page);
        this.makeActiveTab(page);
    }
}
</script>
<style scoped>
    .overlay.dark {
        background: rgba(0, 0, 0, 0.3);
        animation: fadeIn .5s forwards;
    }
    .box-body.white {
        background: #fff;
    }
    .box.modal-animation {
        animation: moveUp .5s forwards;
    }   
    .box.modal-close-animation {
        animation: moveUp .5s reverse;
    }
    .placeholder {
        padding: 0.2rem;
        background-color: rgba(218, 218, 218, 0.596);
        border-radius: 10%;
    }
    .placeholder-help {
        margin-top: 1.5rem;
        color: rgba(107, 107, 107, 0.596)
    }
    @keyframes fadeIn {
        0% {
            background:rgba(0,0,0,.0);
        }
        100% {
            background:rgba(0,0,0,.3);
        } 
    }
    @keyframes moveUp {
        0% {
            transform:translateY(1000px)
        }
        100% {
            transform:translateY(0px)
        } 
    }
</style>